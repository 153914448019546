<template>
  <div>
    <loader size="xxs" :loader-image="false" v-if="loading" />
    <div class="flex flex-col w-full" v-else>
      <card class="w-full flex flex-col mt-2 p-5">
        <div class="w-full gap-5 mb-6 flex">
          <Card class="w-2/6 text-white py-6 px-4" style="background: #3454d1">
            <div class="flex justify-between">
              <card class="p-2" style="height: 40px; background: #3454d1">
                <Icon
                  class-name="text-white -mb-2"
                  icon-name="expense-union"
                  size="xsm"
                />
              </card>
              <div class="flex flex-col">
                <span class="text-xs font-semibold uppercase"
                  >Total Amount Disbursed (YTD)</span
                >
                <span class="text-lg font-extrabold">{{ convertToCurrency(summaryData.totalDisbursedAmount) }}</span>
              </div>
              <div class="border bg-white w-0" />
            </div>
          </Card>
          <Card class="w-2/6 py-6 px-4">
            <div class="flex justify-between">
              <card class="p-2" style="height: 40px">
                <Icon
                  class-name="text-mediumSeaGreen -mb-2"
                  icon-name="expense-union"
                  size="xsm"
                />
              </card>
              <div class="flex flex-col">
                <span class="text-xs font-semibold uppercase text-jet"
                  >Total Repaid (YTD)</span
                >
                <span class="text-lg font-extrabold text-mediumSeaGreen"
                  >{{ convertToCurrency(summaryData.totalRepaymentAmount) }}</span
                >
              </div>
              <div class="border bg-white w-0" />
            </div>
          </Card>
          <Card class="w-2/6 py-6 px-4">
            <div class="flex justify-between">
              <card class="p-2" style="height: 40px">
                <Icon
                  class-name="text-desire -mb-2"
                  icon-name="expense-union"
                  size="xsm"
                />
              </card>
              <div class="flex flex-col">
                <span class="text-xs font-semibold uppercase text-jet">
                  Outstanding Balance (YTD)
                </span>
                <span class="text-lg font-extrabold text-desire"
                  >{{ convertToCurrency(summaryData.outstandingRepaymentAmount) }}</span
                >
              </div>
              <div class="border bg-white w-0" />
            </div>
          </Card>
        </div>
        <template v-slot:footer>
          <CardFooter>
            <template v-slot:search>
              <SearchInput
                placeholder="Search"
                class="search"
                style="width: 300px; margin-top: 0.1rem"
              />
            </template>
            <template v-slot:selection>
              <CSelect
                placeholder="All functions"
                variant="w-full h-10"
                class="h-10 mr-2"
                style="margin-top: -0.4rem"
              />
              <CSelect
                placeholder="1 Location Selected"
                variant="w-full h-10"
                class="h-10 mr-2"
                style="margin-top: -0.4rem"
              />
            </template>
            <template v-slot:calendar>
              <div class="flex" style="width: 180px; padding: 5px 8px">
                <icon icon-name="calendar" class="icon mt-1" />
                <div
                  class="border-r"
                  style="width: 1px; margin: 8px 8px; border-color: #321c3b"
                />
                <div
                  class="text-darkPurple h-full"
                  style="width: 91px; font-size: 16px; margin: 4px 6px"
                >
                  Year to Date
                </div>
                <icon icon-name="caret-down" class="icon mt-1" />
              </div>
            </template>
          </CardFooter>
        </template>
      </card>
      <div class="flex">
        <Table
          :headers="advanceLoansHeader"
          :items="advanceLoansData"
          class="w-full mt-4 mb-4"
          :has-checkbox="false"
          :loading="false"
        >
          <template v-slot:item="{ item }">
            <div
              v-if="item.employee"
              style="width: 230px"
              class="pt-3 pb-3 flex"
            >
              <div>
                <img
                  class=""
                  :src="item.data.employee.photo"
                  alt="photo"
                  v-if="item.data.employee.photo"
                  style="height: 30px; width: 30px; border-radius: 5px"
                />
                <div
                  style="height: 30px; width: 30px; border-radius: 5px"
                  class="text-blueCrayola border text-center font-semibold pt-2"
                  v-else
                >
                  {{
                    $getInitials(
                      `${item.data.employee.fname} ${item.data.employee.lname}`
                    )
                  }}
                </div>
              </div>
              <div class="flex flex-col">
                <span
                  class="font-bold cursor-pointer ml-2 capitalize"
                  style="color: #321c3b"
                  >{{ item.data.employee.fname }}
                  {{ item.data.employee.lname }}</span
                >
                <span class="cursor-pointer ml-2 capitalize text-gray-500">{{
                  item.data.employee.designation
                }}</span>
              </div>
            </div>
            <div v-if="item.principalAmount">
              <span>
                {{ convertToCurrency(item.data.principalAmount) }}
              </span>
            </div>
            <div v-if="item.interestAmount">
              <span>
                {{ convertToCurrency(item.data.interestAmount) }}
              </span>
            </div>
            <div v-if="item.repaymentAmount">
              <span>
                {{ convertToCurrency(item.data.repaymentAmount) }}
              </span>
            </div>
            <div v-if="item.amountRepaidToDate">
              <span>
                {{ convertToCurrency(item.data.amountRepaidToDate) }}
              </span>
            </div>
            <div v-if="item.outstandingBalance">
              <span>
                {{ convertToCurrency(item.data.outstandingBalance) }}
              </span>
            </div>
          </template>
        </Table>
      </div>
      <card class="p-5 w-full flex gap-9">
        <div class="w-max text-lg mr-5 font-bold text-darkPurple">Total</div>
        <div
          class="w-2/12 border border-dashed mr-5 mt-4"
          style="height: 1px"
        />
        <div class="flex w-9/12 gap-3">
          <div class="mt-1 w-full">
            <p class="font-black uppercase text-romanSilver text-xs">
              Total Principal
            </p>
            <p class="text-lg font-bold text-darkPurple">
              {{ convertToCurrency(totalPrincipalAmt) }}
            </p>
          </div>
          <div class="mt-1 w-full">
            <p class="font-black uppercase text-romanSilver text-xs">
              Total Interest
            </p>
            <p class="text-lg font-bold text-darkPurple">
              {{ convertToCurrency(totalInterestAmt) }}
            </p>
          </div>
          <div class="w-full mt-1">
            <p class="font-black uppercase text-romanSilver text-xs">
              Total Repayable
            </p>
            <p class="text-lg font-bold text-darkPurple">{{ convertToCurrency(totalRepaymentAmt) }}</p>
          </div>
          <div class="w-full mt-1">
            <p class="font-black uppercase text-romanSilver text-xs">
              Total Paid
            </p>
            <p class="text-lg font-bold text-darkPurple">{{ convertToCurrency(totalRepaid) }}</p>
          </div>
          <div class="w-full mt-1">
            <p class="font-black uppercase text-romanSilver text-xs">
              Total Outstanding
            </p>
            <p class="text-lg font-bold text-blueCrayola">{{ convertToCurrency(totalOutstanding) }}</p>
          </div>
        </div>
      </card>
    </div>
  </div>
</template>

<script>
import SearchInput from "@scelloo/cloudenly-ui/src/components/search-input";
import CSelect from "@scelloo/cloudenly-ui/src/components/select";
import Table from "@scelloo/cloudenly-ui/src/components/table";
import Card from "@/components/Card";
import Icon from "@/components/Icon";
import CardFooter from "@/components/CardFooter";

export default {
  name: "ConsolidatedAdvance",
  components: {
    Card,
    Icon,
    SearchInput,
    CSelect,
    CardFooter,
    Table,
  },
  props: {
    mode: {
      type: String,
      default: "admin",
    },
  },
  data() {
    return {
      advanceLoansHeader: [
        { title: "EMPLOYEE", value: "employee" },
        { title: "PRINCIPAL", value: "principalAmount" },
        { title: "Total Interest", value: "interestAmount" },
        { title: "Amount Repayable", value: "repaymentAmount" },
        { title: "Repaid to Date", value: "amountRepaidToDate" },
        { title: "Oustanding Balance", value: "outstandingBalance" },
      ],
      advanceLoansData: [],
      summaryData: {},
      totalPrincipalAmt: 0,
      totalInterestAmt: 0,
      totalRepaymentAmt: 0,
      totalRepaid: 0,
      totalOutstanding: 0,
      loading: true
    };
  },
  methods: {
    getRequests(params) {
      this.loading = true;
      if (this.mode !== "ess") {
        /* this.$_getAdminEmployeesAdvance(params)
          .then(({ data }) => {
            this.requests = data.data;
            this.initialRequests = data.data;
            this.requests = this.requests.map((element) => ({
              employee: element.employee,
              ...element.request,
            }));
            this.metaData = data.meta;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          }); */
      } else {
        this.$_getManagerConsolidatedAdvanceAndLoanForEmployees(params)
          .then(({ data }) => {
            const advanceLoanData = data.employees;
            advanceLoanData.forEach((item) => {
              this.advanceLoansData.push({
                userId: item.request.userId,
                interestAmount: item.request.interestAmount,
                principalAmount: item.request.principalAmount,
                repaymentAmount: item.request.repaymentAmount,
                amountRepaidToDate: item.amountRepaidToDate,
                employee: item.employee,
                outstandingBalance: item.outstandingBalance,
              });
              this.totalPrincipalAmt += item.request.principalAmount;
              this.totalInterestAmt += item.request.interestAmount;
              this.totalOutstanding += item.outstandingBalance;
              this.totalRepaid += item.amountRepaidToDate;
              this.totalRepaymentAmt += item.request.repaymentAmount;
            });
            this.metaData = data.meta;
            this.loading = false;
          })
          .catch(() => {
            this.loading = false;
          });
      }
    },
    async requestAdvance() {
      if (this.mode === "ess") {
        this.showError = false;
        this.getRequests("&page=1&perPage=50");
        await this.getStats();
        return;
      }
      try {
        await this.$handlePrivilege("advance", "viewRegister");
        this.showError = false;
        this.getRequests("&page=1&perPage=50");
        await this.getStats();
      } catch (error) {
        this.showError = true;
      }
    },

    getSummary() {
      if (this.mode === "ess") {
        this.$_getManagerConsolidatedAdvanceLoanSummary().then((response) => {
          this.summaryData = response.data;
        });
      }
    },
  },
  mounted() {
    this.getRequests("");
    this.getSummary();
  },
};
</script>

<style scoped>
.icon {
  width: 24px;
  height: 24px;
  padding: 3px;
}
</style>
